<template>
  <b-card no-body>
    <div class="m-2">
      <b-row>
        <job-list-add-new
          v-if="$can('create', 'Job')"
          :is-add-new-job-modal-show.sync="isAddNewJobModalShow"
          @refetch-data="refetchData"
        />

        <job-list-edit
          v-if="$can('update', 'Job')"
          :is-edit-job-modal-show.sync="isEditJobModalShow"
          :data.sync="selectedJob"
          @refetch-data="refetchData"
        />

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('common.entries') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-if="$can('create', 'Job')"
            variant="primary"
            @click="isAddNewJobModalShow = true"
          >
            {{ $t('job.addBtn') }}
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              debounce="500"
              class="d-inline-block mr-1"
              :placeholder="$t('common.search') + '...'"
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="status => status.value"
              class="job-filter-select"
              :placeholder="$t('common.filter.status')"
              label="text"
            >
              <template #selected-option="{ text }">
                <span class="text-truncate overflow-hidden">
                  {{ text }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refJobListTable"
      :items="fetchJobs"
      responsive
      :fields="translatedTableColumns"
      primary-key="_id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('message.empty')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <template #table-busy>
        <div class="text-center mt-2">
          <b-spinner
            variant="primary"
            label="Loading..."
          />
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(is_active)="data">
        <template v-if="data.value">
          <b-badge
            pill
            variant="light-success"
          >
            Active
          </b-badge>
        </template>
        <template v-else>
          <b-badge
            pill
            variant="light-secondary"
          >
            Inactive
          </b-badge>
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            v-if="$can('update', 'Job')"
            :id="`job-row-${data.item._id}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="openEditJobModal(data.item)"
          />
          <b-tooltip
            v-if="$can('update', 'Job')"
            :title="$t('job.tooltip')"
            class="cursor-pointer"
            :target="`job-row-${data.item._id}-send-icon`"
            triggers="hover"
            placement="left"
          />

          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :disabled="!$can('delete', 'Job')"
              @click="confirmDelete(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('common.delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{
            $tc('common.showing', dataMeta.of, {
              first: dataMeta.from,
              last: dataMeta.to,
              total: dataMeta.of,
            })
          }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalJobs"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import useJobsList from './useJobList'
import JobListAddNew from './JobListAddNew.vue'
import JobListEdit from './JobListEdit.vue'
import jobStoreModule from '../jobStoreModule'
import 'animate.css'

export default {
  components: {
    JobListAddNew,
    JobListEdit,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BTooltip,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,

    vSelect,
  },
  computed: {
    translatedTableColumns() {
      return this.tableColumns.map(column => ({
        ...column,
        label: this.$t(`job.tableHeader.${column.key}`),
      }))
    },
  },
  methods: {
    confirmDelete({ _id: id, profession_name: name }) {
      this.$swal({
        title: `Delete ${name} ?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__shakeX',
        },
        buttonsStyling: false,
      }).then(async result => {
        try {
          if (result.value) {
            const res = await store.dispatch('master-job/deleteJob', { id })

            if (res.data.code !== 400) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                timer: 2000,
                text: 'Your data has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              this.refJobListTable.refresh()
            } else {
              this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            }
          }
        } catch (e) {
          this.$swal({
            title: 'Error!',
            text: 'Something went wrong!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },

  setup() {
    const JOB_MASTER_STORE_MODULE_NAME = 'master-job'

    // Register module
    if (!store.hasModule(JOB_MASTER_STORE_MODULE_NAME)) store.registerModule(JOB_MASTER_STORE_MODULE_NAME, jobStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JOB_MASTER_STORE_MODULE_NAME)) store.unregisterModule(JOB_MASTER_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { text: 'Active', value: true },
      { text: 'Inactive', value: false },
    ]

    const isAddNewJobModalShow = ref(false)
    const isEditJobModalShow = ref(false)
    const selectedJob = ref(null)

    const openEditJobModal = data => {
      selectedJob.value = data
      isEditJobModalShow.value = true
    }

    const {
      fetchJobs,
      refetchData,
      deleteJob,
      sortBy,
      perPageOptions,
      isSortDirDesc,
      tableColumns,
      perPage,
      searchQuery,
      statusFilter,
      refJobListTable,
      currentPage,
      totalJobs,
      dataMeta,
    } = useJobsList()

    return {
      fetchJobs,
      refetchData,
      deleteJob,
      sortBy,
      perPage,
      searchQuery,
      perPageOptions,
      statusFilter,
      isSortDirDesc,
      tableColumns,
      refJobListTable,
      totalJobs,
      currentPage,
      dataMeta,

      openEditJobModal,
      isAddNewJobModalShow,
      isEditJobModalShow,
      selectedJob,
      statusOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.job-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
