<template>
  <!-- Add new job modal -->
  <b-modal
    id="modal-new-job"
    :visible="isAddNewJobModalShow"
    cancel-variant="outline-secondary"
    :cancel-title="$t('common.cancel')"
    centered
    :title="$t('job.form.header')"
    :ok-disabled="isLoading"
    @ok="handleOk"
    @hidden="resetModal"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="nameState"
        :label="$t('job.form.name')"
        :invalid-feedback="$t('job.form.nameInvalid')"
        label-for="job"
      >
        <b-form-input
          id="job"
          v-model="jobName"
          type="text"
          :state="nameState"
          :placeholder="$t('job.form.namePlaceholder')"
          required
        />
      </b-form-group>
    </form>
    <template v-slot:modal-ok>
      <div class="d-flex align-center">
        <span
          v-if="isLoading"
          class="spinner-border spinner-border-sm mr-1"
          role="status"
          aria-hidden="true"
        ></span>
        {{ $t('common.create') }}
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BFormGroup, BFormInput, BModal, VBModal } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-job-modal-show',
  },
  props: {
    isAddNewJobModalShow: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const jobName = ref('')
    const nameState = ref(null)
    const form = ref(null)
    const isLoading = ref(false)

    const toast = useToast()

    const checkFormValidity = () => {
      nameState.value = form.value.checkValidity()
      return nameState.value
    }

    const handleSubmit = () => {
      if (!checkFormValidity()) {
        // eslint-disable-next-line no-useless-return
        return
      }

      isLoading.value = true

      store
        .dispatch('master-job/addJob', jobName.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-job-modal-show', false)
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success creating new job',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new job',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmit()
    }

    const resetModal = () => {
      jobName.value = ''
      nameState.value = null
      emit('update:is-add-new-job-modal-show', false)
    }

    return {
      jobName,
      form,
      handleSubmit,
      nameState,
      handleOk,
      resetModal,
      isLoading,
    }
  },
}
</script>
