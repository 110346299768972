import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchJobs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/profession', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editJob(ctx, { id, professionName }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/profession/update/${id}`, { profession_name: professionName })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateJobState(ctx, { id, isActive }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/profession/setstatus', { id, status: isActive })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addJob(ctx, professionData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/profession/create', { profession_name: professionData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteJob(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/profession/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
