import { ref, watch, computed } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useJobsList() {
  const toast = useToast()

  const refJobListTable = ref(null)

  const tableColumns = [
    { key: 'profession_name', label: 'Job', sortable: true },
    { key: 'is_active', label: 'Status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalJobs = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('_id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refJobListTable.value ? refJobListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalJobs.value,
    }
  })

  const refetchData = () => {
    refJobListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const deleteJob = ctx => {
    const { _id: id } = ctx.item
    store
      .dispatch('master-job/deleteJob', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Job deleted successfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting job',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchJobs = (ctx, callback) => {
    store
      .dispatch('master-job/fetchJobs', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { professions, total } = response.data.data

        callback(professions)
        totalJobs.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching jobs' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchJobs,
    deleteJob,
    tableColumns,
    perPage,
    currentPage,
    totalJobs,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refJobListTable,

    statusFilter,

    refetchData,
  }
}
